import { vcUiUtils } from '@lxlabs/vc-ui-components';
const { makeStore, createReducerFromObject } = vcUiUtils;

import { customPopupReducer, CUSTOM_POPUP_ACTIONS } from './customPopupReducer';

const customPopupInitialState = {
  contactUsOpen: false,
  leavingDisclaimerOpen: false,
}

const [CustomPopupProvider, useCustomPopupDispatch, useCustomPopupState] = makeStore(createReducerFromObject(customPopupReducer), customPopupInitialState);

function useCustomPopupActions() {
  const dispatch = useCustomPopupDispatch();

  const setContactUsOpen = (open) => {
    dispatch({
      type: CUSTOM_POPUP_ACTIONS.SET_CONTACT_US_OPEN,
      contactUsOpen: open
    })
  }

  const setLeavingDisclaimerOpen = (open) => {
    dispatch({
      type: CUSTOM_POPUP_ACTIONS.SET_LEAVING_DISCLAIMER_OPEN,
      leavingDisclaimerOpen: open
    })
  }

  return { setContactUsOpen, setLeavingDisclaimerOpen };
}

export { CustomPopupProvider, useCustomPopupActions, useCustomPopupState };