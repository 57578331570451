import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { CustomPopupProvider } from "./reducers/customPopup";
import {
  vcUiReducer,
  ThemeProvider as UiThemeProvider,
  vcUiUtilHooks
} from "@lxlabs/vc-ui-components";
import { ThemeProvider } from "styled-components/macro";
import theme from "./config/theme";
import "./index.css";
import "./assets/fonts.css";

process.env.REACT_APP_GTM_ID && vcUiUtilHooks.useGa.gaInitalise({ gtmId: process.env.REACT_APP_GTM_ID })

const { UiProvider } = vcUiReducer;

ReactDOM.render(
  <React.StrictMode>
    <UiProvider>
      <CustomPopupProvider>
        <ThemeProvider theme={theme}>
          <UiThemeProvider theme={theme}>
            <App />
          </UiThemeProvider>
        </ThemeProvider>
      </CustomPopupProvider>
    </UiProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
