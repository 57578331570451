const variables = {
  colors: {
    primary: "#2480C1",
    primaryHover: "#660099",
    primaryDark: "#122668",
    secondary: "#273D98",
    secondaryHover: "#FA3E8D",
    secondaryDark: "#FF8D22",
    tertiary: "#FA3D8C", 
    tertiaryHover: "", 
    tertiaryDark: "", 
    white: "#FFF",
    black: "#000",
    lightgrey: "#F5F5F5",
    grey: "#DBD9D9",
    darkgrey: "#796E65",
  },
}

const alert = {
  alertBgPrimary: variables.colors.primary,
  alertBgSecondary: variables.colors.secondary,
  alertBgTertiary: variables.colors.grey,
  alertBgTertiaryHover: variables.colors.white,

  alertBorderPrimary: variables.colors.primary,
  alertBorderSecondary: variables.colors.secondary,

  alertTextPrimary: variables.colors.primary,
  alertTextSecondary: variables.colors.secondary,
}

const buttons = {
  buttonBgPrimary: variables.colors.primary,
  buttonBgSecondary: variables.colors.white,

  buttonBorderPrimary: variables.colors.primary,
  buttonBorderSecondary: variables.colors.white,

  buttonTextPrimary: variables.colors.primary,
  buttonTextSecondary: variables.colors.white,
}

const loadingScreen = {
  loadingScreenBorderBottom: variables.colors.primary, 
  loadingScreenFont: '',
  loadingScreenLineHeight: '1.1'
}

const maskSlider = {
  maskSliderTextPrimary: variables.colors.primary,
}

const videoWithTimeline = {
  videoWithTimelineBgPrimary: variables.colors.primary,
}

const navigation = {
  navBgPrimary: variables.colors.primary,
  navBgPrimaryHover: variables.colors.tertiary,
  navBgPrimaryActive: variables.colors.white,

  navTabActive: variables.colors.secondary,
  navTabInactive: variables.colors.primary,
  navTabPrimaryActive: variables.colors.white,

  navTextPrimary: variables.colors.white,
  navTextSecondary: variables.colors.primary,
  navTextTertiary: variables.colors.darkgrey,

  navSkew: "0",
}

const theme = {
  alert,
  buttons,
  loadingScreen,
  maskSlider,
  navigation,
  videoWithTimeline
}

export default theme
