import React, { lazy, Suspense } from "react";
import showWarning from "./utils/showWarning";
import {
  LEAVING_DISCLAIMER,
} from "./config/app";
import {
  LeavingDisclaimer,
  Alert
} from "@lxlabs/vc-ui-components";
const ThreeDScene = lazy(() => import("./components/ThreeDScene"));

function App() {
  return (
    <>
      <Suspense fallback={<></>}>
        <div style={{ position: "relative" }}>
          <Alert
            message="For an optimal experience, please switch your browser to Google Chrome."
            iosMessage="For an optimal experience, please switch your browser to Safari."
            closeText="OK"
            show={showWarning()}
            menuBg={""}
            logoSymbol={""}
          />

          <ThreeDScene />

          <LeavingDisclaimer
            setShowModal={false} // set state
            disclaimer={false}
            disclaimerCopy={LEAVING_DISCLAIMER}
          />
        </div>
      </Suspense>
    </>
  );
}

export default App;
