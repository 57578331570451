export const CUSTOM_POPUP_ACTIONS = {
  SET_CONTACT_US_OPEN: 'SET_CONTACT_US_OPEN',
  SET_LEAVING_DISCLAIMER_OPEN: 'SET_LEAVING_DISCLAIMER_OPEN'
}

export const customPopupReducer = {
  [CUSTOM_POPUP_ACTIONS.SET_CONTACT_US_OPEN]: (state, action) => {
    return {
      ...state,
      contactUsOpen: action.contactUsOpen,
    }
  },
  [CUSTOM_POPUP_ACTIONS.SET_LEAVING_DISCLAIMER_OPEN]: (state, action) => {
    return {
      ...state,
      leavingDisclaimerOpen: action.leavingDisclaimerOpen,
    }
  }
}